<template>
  <div class="statement">
    <div class="month-box">
      <span class="month-span">选择月份</span>
      <el-date-picker
        class="my-month-picker"
        v-model="monthValue"
        type="month"
        placeholder="请选择月份"
        @change="changeMonth"
      >
      </el-date-picker>
    </div>
    <div class="chart-box">
      <v-chart
        ref="my-chart"
        class="v-chart"
        autoresize
        :option="line_personnel"
      ></v-chart>
    </div>
  </div>
</template>

<script>
import {
  getMemberLogSearchApi,
  getMemberLogStatisticApi,
} from "@/request/api.js";
import moment from "moment";

import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { TitleComponent, TooltipComponent } from "echarts/components";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
]);
export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      monthValue: "",
      line_personnel: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            axis: "auto",
            type: "cross",
          },
          formatter: (params) => {
            let tooltipTemplate = params[0].name;
            for (let i = 0; i < params.length; i++) {
              let item = params[i];
              if (item.seriesType === "line" && item.data) {
                tooltipTemplate += `
                  <div style="display: flex;justify-content: space-between;">
                    <span style="margin:0 30px 0 0;">
                    ${item.marker}
                    ${item.seriesName}
                    </span>
                    <span style="font-weight: bold;">${item.data || 0} 次</span>
                  </div>
                  `;
              }
            }
            return tooltipTemplate;
          },
        },
        legend: {
          data: [],
        },
        grid: { top: 100, left: 100, right: 20, bottom: 20 },
        backgroundColor: "transparent",
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            name: "系统操作次数",
            nameGap: 30,
            nameLocation: "end",
            nameTextStyle: { color: "#fff", fontSize: 14, align: "right" },
            type: "value",
          },
        ],
        series: [],
      },
    };
  },
  created() {
    this.monthValue = `${moment().format("YYYY")}-${moment().format("MM")}`;
    this.getMemberLogStatisticApi();
  },
  mounted() {
    setTimeout(() => {
      this.getMemberLogSearchApi(this.monthValue);
    }, 80);
  },
  methods: {
    changeMonth(e) {
      this.getMemberLogSearchApi(moment(e).format("YYYY-MM"));
    },
    async getMemberLogSearchApi(date) {
      this.line_personnel.series.forEach((t) => {
        t.data = [];
      });

      this.$refs["my-chart"].clear();
      let { code, results } = await getMemberLogSearchApi({ date });
      if (code != 0) return;
      let values = Object.values(results.data);

      this.line_personnel.xAxis[0].data = Object.keys(results.data).map(
        (items) => {
          return (items = items.substring(items.length - 2) + "号");
        }
      );

      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < values[i].length; j++) {
          for (let k = 0; k < this.line_personnel.series.length; k++) {
            if (this.line_personnel.series[k].name == values[i][j].name) {
              this.line_personnel.series[k].data[i] = values[i][j].count || 0;
            }
          }
        }
      }
    },
    async getMemberLogStatisticApi() {
      const { code, results } = await getMemberLogStatisticApi();
      if (code != 0) return;
      results.data.forEach((items) => {
        items;
        let obj = {
          name: "",
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [],
        };
        obj.name = items.name;
        this.line_personnel.series.push(obj);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.statement {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 48px 0;

  .month-box {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 40px 0;

    .month-span {
      margin-right: 14px;
      color: #fff;
      font-size: 14px;
    }

    /deep/.my-month-picker {
      .el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
      }
    }
  }

  .chart-box {
    width: 100%;
    height: 75%;

    .v-chart {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
